import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import mitt from 'mitt'
import sharedScripts from "@/dependencies/sharedScripts";

const eventBus = mitt();

// sessionStorage persistence
const storageKey = "cashierApp";
const session = {
    get() {
        const storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        return storage;
    },
    add(key, value) {
        let storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        storage[key] = value;
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    save(storage) {
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    deleteAll() {
        sessionStorage.removeItem(storageKey);
    }
}

let serverStatus = {
    serverBusy: false,
    busyText: ""
}

const authenticationCheck = async (vueInstance) => {
    // checkAndRefreshSession() returns either an updated state or a status object with errors
    let authenticationCheck = await sharedScripts.checkAndRefreshSession(vueInstance, vueInstance.cashierState);
    if (authenticationCheck.hasOwnProperty("ok") && !authenticationCheck.ok) {
        eventBus.emit("updateStatus", authenticationCheck);
        if (authenticationCheck.forceLogout) eventBus.emit("forceLogout");
    } else {

        // let mergedCashierState = {
        //     ...vueInstance.cashierState,
        //     ...payload,
        // };

        // vueInstance.cashierState = mergedCashierState;

        // eventBus.emit("updateCashierState", authenticationCheck);

        // console.log(vueInstance.cashierState);

    }
    return await authenticationCheck;
}

const getUserPermissions = async (userId, token) => {
    let baseUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
    let headerObj = new Headers();
    headerObj.append("Content-Type", "application/json; charset=utf-8");
    headerObj.append("Authorization", `Bearer ${token}`);
    let requestUrl = new URL(`/api/v1/user/${userId}/permissions`, baseUrl);
    let request = new Request(requestUrl.toString(), {
        method: 'GET',
        headers: headerObj,
    })
    try {
        const response = await fetch(request);
        let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

        if (fetchStatus && !fetchStatus.ok || fetchStatus && response === "Bad OldAccessToken") {
            console.error(response);
            return fetchStatus;
        }

        let dataObj = await response.json();
        return dataObj;

    } catch (e) {
        console.error(e);
    }
}

// globally set dateOptions for all list views
const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
};

// Global app status notification properties
const globalStatus = {
    code: null,
    message: null,
    ok: true,
    userMustDismiss: false,
    created: 0,
    createdTimeString: "",
    createdDateTimeString: "",
    clientSessonTimeout: false,
    forceLogout: false
};

// Global keypad config properties
const globalInputConfig = {
    locale: false,
    inputField: null,
    keypadInput: 0,
    limitNumber: process.env.VUE_APP_PHONE_LENGTH, // default limit for number of digits. Now moved to Env Variables.
    subtract: false
};

// Global player account properties
// const globalAccountProperties = {
//     accessCodeConfirmed: false,
//     accountAccessCode: null,
//     playerDisplayName: "",
//     playerUserId: "",
//     playerPhone: "",
//     casinoId: 0,
//     fundsLocks: [],
//     balance: {
//         regularAU: 0,
//         promoAU: 0,
//     },
//     buyInOffers: []
// };

// global default list limits
const limitOptions = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "250", value: 250 },
    { text: "500", value: 500 }
];
let limit = 20;

// International currency conversion
const divisor = 100;
const toLocaleCurrencyIntl = (fundsAU) => {
    if (isNaN(fundsAU)) fundsAU = 0;
    return new Intl.NumberFormat(process.env.VUE_APP_LOCALE_STRING, { style: 'currency', currency: process.env.VUE_APP_CURRENCY }).format(fundsAU / divisor);
}

// Generate a CSS background hex code color from the first part of the user id
const generateUserIdColor = (userId) => {
    let colors = {
        background: 0,
        color: 0,
    };
    let guidArray = userId.match(/[^-]/g);
    let backgroundHex = [];
    for (let i = 0; i < 6; i++) {
        backgroundHex.push(guidArray[i]);
    }
    colors.background = backgroundHex.join("");
    colors.color = (Number(`0x1${colors.background}`) ^ 0xffffff).toString(16).substring(1);
    return colors;
}

function calculateBuyInTransactionBalances(originalBalances = 0, newBalances = 0) {
    let originalReg = originalBalances.regularAU;
    let originalPromo = originalBalances.promoAU;
    let transactionAmounts = {};
    transactionAmounts.regularAU = newBalances.regularAU - originalReg;
    transactionAmounts.promoAU = newBalances.promoAU - originalPromo;
    return transactionAmounts;
}

async function getCashierBankSummary(context, cashierBankId, abortSignal) {
    let thisStatus = Object.assign({}, globalStatus);

    try {
        let requestUrl = new URL(`/api/v1/cashier/bank/${cashierBankId}/summary`, context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Authorization", `Bearer ${context.cashierState.accessToken}`);
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: "GET",
            headers: headerObj,
            signal: abortSignal,
        });

        const response = await fetch(request);

        let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

        if (fetchStatus && !fetchStatus.ok) {
            thisStatus.ok = false;
            eventBus.emit("updateStatus", fetchStatus);
            if (fetchStatus.code === 409 || fetchStatus.code === 401) eventBus.emit("forceLogout");
            return false;
        }

        let dataJson = await response.json();
        return dataJson;
    } catch (e) {
        thisStatus.ok = false;
        thisStatus.message = e
        eventBus.emit("updateStatus", thisStatus);
        eventBus.emit("closeCashierBankSummaryModal");
        return false;
    }
}

async function closeBank(context, bankId = null) {
    let closeStatus = Object.assign({}, this.globalStatus);
    let currentBankId = bankId || context.cashierState.currentBank.id;
    try {
        let body = {
            "CashierBankId": currentBankId
        };
        let requestUrl = new URL("/api/v1/cashier/bank/close", context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Authorization", `Bearer ${context.cashierState.accessToken}`);
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: 'POST',
            body: JSON.stringify(body),
            headers: headerObj,
        });

        const response = await fetch(request);

        let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

        // let dataJson = await response.json();

        if (fetchStatus && !fetchStatus.ok) {
            closeStatus.code = fetchStatus.code;
            closeStatus.message = fetchStatus.message;
            closeStatus.ok = false;
            closeStatus.userMustDismiss = true;
        } else {
            closeStatus.code = response.status;
            closeStatus.message = `Bank ${currentBankId} has been closed`;
            closeStatus.ok = true;
            closeStatus.userMustDismiss = false;
        }
        return closeStatus;
    } catch (e) {
        console.error(e);
        closeStatus.code = null;
        closeStatus.message = e;
        closeStatus.ok = false;
        closeStatus.userMustDismiss = true;
        return closeStatus;
    }
}

const serialSupported = "serial" in navigator;

const textCodes = {
    HT: String.fromCharCode(9), // Horizontal Tab.
    LF: String.fromCharCode(10), // Line feed.
    CR: String.fromCharCode(13), // Carriage return.
    DLE: String.fromCharCode(16), // Data link escape.
    CAN: String.fromCharCode(24), // Cancel print data.
    ESC: String.fromCharCode(27), // Escape character.
    FS: String.fromCharCode(28), // File separator; end of file.
    GS: String.fromCharCode(29), // Group separator; between sections of data.
    RS: String.fromCharCode(30), // Record separator; end of a record or row.
    US: String.fromCharCode(31), // Unit separator; between fields of a record, or members of a row.
    STARDIVIDER: "******************************************", // 42 char wide at standard font size.
    LINEDIVIDER: "------------------------------------------", // 42 char wide at standard font size.
}

const formatSpacedLine = (lineCategory, lineValue, spacerCharacter = " ") => {
    let spacers = [];
    let spaceRemaining = 42;
    let whiteSpacesNeeded = spaceRemaining - lineCategory.length - lineValue.length;
    for (let i = 0; i < whiteSpacesNeeded; i++) {
        spacers.push(spacerCharacter);
    }
    spacers = spacers.join("");
    return `${lineCategory}${spacers}${lineValue}`;
}

const setFontSize = (text, size) => {
    // Sets font to size specified by hex code and then sets it back to default after the string.
    // 0x00 is default; Format hex number as follows 0x[width][height]
    return `${textCodes.GS}!${size}${text}${textCodes.GS}!\x00`;
}

const prepareClosingReceipt = (cashierState, bankSummary, inGameCurrencyTool) => {

    let full = inGameCurrencyTool.displayType.full;
    
    // First element of printLines array; Initialize printer, set the code page to 16 (Windows-1252),
    // and set justify center for the header section.  The Windows-1252 encoding gives us access to 
    // several currency symbols.  For this to work, we need to use the win1252Encoder later when
    // converting all this to bytes for the serial port.
    // https://en.wikipedia.org/wiki/Windows-1252
    let printLines = [`${textCodes.ESC}@${textCodes.ESC}t\x10${textCodes.ESC}a1`];

    // Header and site branding section
    printLines.push(setFontSize("Cashier Receipt", "\x10"));
    printLines.push(textCodes.STARDIVIDER);
    printLines.push(textCodes.LF);
    printLines.push(setFontSize(cashierState.casinoName, "\x11"));
    printLines.push(textCodes.LF);
    printLines.push(textCodes.STARDIVIDER);
    printLines.push(setFontSize("Close Bank", "\x20"));
    printLines.push(textCodes.LF);
    printLines.push(new Date(bankSummary.closeDate).toLocaleTimeString([], dateOptions));
    printLines.push(textCodes.LF);

    // Summary section
    printLines.push(formatSpacedLine("Starting Bank Balance:", inGameCurrencyTool.formatFromAU(bankSummary.openingBalanceRegularAU, full)));
    printLines.push(formatSpacedLine("Additional Bank Fills:", inGameCurrencyTool.formatFromAU(bankSummary.intermediateFillsRegularAU, full)));
    printLines.push(formatSpacedLine("Regular to Players:", inGameCurrencyTool.formatFromAU(bankSummary.toPlayerAccountsRegularAU, full)));
    printLines.push(formatSpacedLine("Promo to Players:", inGameCurrencyTool.formatFromAU(bankSummary.toPlayerAccountsPromoAU, full)));
    printLines.push(formatSpacedLine("Regular from Players:", inGameCurrencyTool.formatFromAU(bankSummary.fromPlayerAccountsRegularAU, full)));
    // printLines.push(formatSpacedLine("Promo from Players:", inGameCurrencyTool.formatFromAU(bankSummary.fromPlayerAccountsPromoAU, full)));
    printLines.push(textCodes.LINEDIVIDER);
    printLines.push(formatSpacedLine("Closing Bank Balance:", inGameCurrencyTool.formatFromAU(bankSummary.closingBalanceRegularAU, full)));
    printLines.push(textCodes.LF);

    // Footer section
    printLines.push(textCodes.LINEDIVIDER);
    printLines.push(textCodes.LF);
    printLines.push(`Cashier: ${bankSummary.cashierDisplayName}`);
    printLines.push(textCodes.LF);
    printLines.push(textCodes.STARDIVIDER);

    // Feed a couple of lines and cut paper
    printLines.push(textCodes.LF);
    printLines.push(textCodes.LF);
    printLines.push(`${textCodes.GS}VB${66}`);

    let printJob = printLines.join(`${textCodes.LF}`);

    return printJob;
}

const app = createApp(App).use(store).use(router);

app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.session = session;
app.config.globalProperties.serverStatus = serverStatus;
app.config.globalProperties.authenticationCheck = authenticationCheck;
app.config.globalProperties.getUserPermissions = getUserPermissions;
app.config.globalProperties.globalStatus = globalStatus;
app.config.globalProperties.limitOptions = limitOptions;
app.config.globalProperties.limit = limit;
app.config.globalProperties.globalInputConfig = globalInputConfig;
// app.config.globalProperties.globalAccountProperties = globalAccountProperties;
app.config.globalProperties.dateOptions = dateOptions;
app.config.globalProperties.toLocaleCurrencyIntl = toLocaleCurrencyIntl;
// app.config.globalProperties.CurrencyTool = CurrencyTool;
app.config.globalProperties.generateUserIdColor = generateUserIdColor;
app.config.globalProperties.closeBank = closeBank;
app.config.globalProperties.serialSupported = serialSupported;
app.config.globalProperties.formatSpacedLine = formatSpacedLine;
app.config.globalProperties.setFontSize = setFontSize;
app.config.globalProperties.textCodes = textCodes;
app.config.globalProperties.prepareClosingReceipt = prepareClosingReceipt;
app.config.globalProperties.calculateBuyInTransactionBalances = calculateBuyInTransactionBalances;
app.config.globalProperties.getCashierBankSummary = getCashierBankSummary;
app.config.globalProperties.rabbitsfootHostUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
app.config.globalProperties.devMode = process.env.VUE_APP_DEV_MODE === "true";
app.config.globalProperties.localeString = process.env.VUE_APP_LOCALE_STRING;
app.config.globalProperties.currency = process.env.VUE_APP_CURRENCY;
app.config.globalProperties.operatorAppsVersion = process.env.OPERATOR_APPS_VERSION;
app.config.globalProperties.phoneNumberLength = process.env.VUE_APP_PHONE_LENGTH;
app.config.globalProperties.casinoGroupName = process.env.VUE_APP_CASINO_GROUP_NAME;
app.config.globalProperties.countryCode = process.env.VUE_APP_COUNTRY_CODE;

app.mount('#app');